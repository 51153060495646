import { ShippingUnitStatus } from '@yms/api/client/v1/data-contracts'
import { HandlerType } from '@yms/api/odata-client/ItgPltYmsApiModel'
import { en as commonEn } from '@yms/common/i18n/en'

export const en = {
  ...commonEn,
  oneTimeSetup: 'One-time setup',
  internalServerError: 'Internal Server Error',
  thisIssueHasBeenLogged: 'This issue has been logged with request id: {traceId}',
  goBack: 'Go Back',
  specifiedEntityDoesNotExist: 'Specified entity does not exist.',
  unknown: 'Unknown',
  yms: 'YMS',
  setup: 'Setup',
  gettingUserData: 'Getting user data',
  'errors.pageNotFound': 'Not Found',
  'errors.pageNotFound.message': "We can't find the page you are looking for.",
  'errors.unexpectedError': 'Unexpected Error',
  'errors.unexpectedError.message':
    "Please report this issue to our team. Try to describe steps you've made when it happened.",
  [HandlerType.ReachStacker]: 'Reach Stacker',
  [HandlerType.TerminalTruck]: 'Terminal Truck',
  'setup.noYardZonesAssigned': 'No yard zones assigned to handler!',
  next: 'Next',
  back: 'Back',
  'setup.selectHandler': 'Please select your Handler',
  'setup.selectYardZones': 'Please select your Yard Zone(s)',
  'setup.selectedHandler': 'Your selected Handler',
  'setup.selectedYardZones': 'Your selected Yard Zone(s)',
  'setup.error.selectYardZones': 'Please select at least one yard zone.',
  'setup.error.selectHandler': 'Please select a handler.',
  'setup.noHandlersAssigned': 'No handlers assigned to your account.',
  'setup.error.failedToSave': 'Failed to save your session. Please try again.',
  pleaseWait: 'Please Wait',
  cancel: 'Cancel',
  confirm: 'Confirm',
  loadingTasks: 'Loading tasks',
  loadingTrucks: 'Loading trucks',
  confirmTask: 'Confirm task',
  licensePlate: 'License plate',
  transportCompany: 'Transport company',
  from: 'From',
  to: 'To',
  yardZone: 'Yard zone',
  block: 'Block',
  slot: 'Slot',
  customer: 'Customer',
  reference: 'Reference',
  group: 'Group',
  remarks: 'Remarks',
  loading: 'Loading',
  closing: 'Closing',
  article: 'Article',
  product: 'Product',
  lotBatch: 'Lot batch',
  bulk: 'Bulk',
  taskConfirmed: 'Task was confirmed',
  unknownError: 'Unknown Error',
  unknownErrorPleaseTryAgainLater: 'Unknown error. Please try again later.',
  licensePlateTruck: 'License plate truck',
  licensePlateTrailer: 'License plate trailer',
  taskRequeued: 'Task requeued',
  loadingShifts: 'Loading shifts',
  finishBefore: 'Finish before',
  detentionDate: 'Detention date',
  meansOfTransport: 'Means of transport',
  'meansOfTransport(out)': 'Means of transport (OUT)',
  unloaded: 'Unloaded',
  closingDate: 'Closing date',
  shippingLine: 'Shipping line',
  returnAddress: 'Return address',
  loaded: 'Loaded',
  slotMustBePresent: 'Slot must be present to confirm move',
  pleaseContactSupport: 'Please contact support.',
  configuration: 'Configuration',
  alternativeReturnAddress: 'Alternative return address',
  plannedUnloadingDate: 'Planned unloading date',
  plannedLoadingDate: 'Planned loading date',
  operatorNotFound: 'Operator not found',
  operatorNotFoundSubtitle: "Please check your PIN and try again. We'll log you out shortly.",
  pushNotificationPermissionGranted: 'Permission granted. Press continue to log in.',
  pushNotificationPermissionDenied:
    'You denied push notification permissions. Please contact technical support.',
  requestPermissions: 'request permissions',
  ymsPushNotificationHeader: 'YMS application needs push notification permission to work properly.',
  ymsPushNotificationMessage: 'Please click button below to request and allow those notifications',
  selected: 'Selected',
  toLocationChanged: 'To location changed',
  sites: 'Sites',
  slots: 'Slots',
  missingSlot: 'Please provide a slot',
  blocks: 'Blocks',
  finalToLocation: 'Final to location',
  plannedToLocation: 'Planned to location',
  backToLocations: 'Back to locations',
  noResults: 'No results',
  pleaseTryWithDifferentQuery: 'Please try with a different query',
  pleaseSelectSlot: 'Please select a slot',
  location: 'Location',
  login: 'Login',
  offline: 'Offline',
  loginRequired: 'Login required',
  'offlineBanner:since':
    'Please connect to a wifi network or move to an area with mobile internet access - Offline since {since}',
  searchNotPossibleOffline: 'Search is not possible while offline.',
  searchForLocation: 'Search for a location',
  logout: 'Logout',
  customerMainReference: 'Customer main reference',
  customerReferences: 'Customer references',
  operationCouldNotFinishYouAreOffline: 'Operation could not be finished because you are offline.',
  trucks: 'Trucks',
  shifts: 'Shifts',
  barges: 'Barges',
  rails: 'Rails',
  userContext: 'User context',
  handlersAndZones: 'Handlers & zones',
  settings: 'Settings',
  userInfoAndPreferences: 'User info & preferences',
  darkMode: 'Dark mode',
  'hi:firstName:lastName': 'Hi {firstName} {lastName}',
  'username:username': 'Username {username}',
  changeContext: 'Change context',
  warehouse: 'Warehouse',
  gate: 'Gate',
  [ShippingUnitStatus.CheckedIn]: 'Checked in',
  [ShippingUnitStatus.Cancelled]: 'Cancelled',
  [ShippingUnitStatus.CheckedOut]: 'Checked out',
  [ShippingUnitStatus.Expected]: 'Expected',
  [ShippingUnitStatus.In]: 'In',
  [ShippingUnitStatus.Out]: 'Out',
  findLocation: 'Find location',
  detentionOrClosing: 'Detention/closing',
  expectedShippingUnitMayNotBeSelected:
    "You can't find the location of an expected shipping unit. Select a shipping unit that has arrived to continue.",
  groupOn: 'Group on',
  onSite: 'On site',
  warning: 'Warning',
  shippingUnits: 'Shipping units',
  yard: 'Yard',
  parentOrderIdentifier: 'Operational order parent identifier',
  pleaseSelectShippingUnit: 'Please select a shipipng unit.',
  'loading:dots': 'Loading{dots}',
  youAreOffline: 'You are offline',
  suWithSameParent: 'SU with same parent',
  detention: 'Detention',
  'same:value': 'Same {value}',
  parentOrder: 'Parent order',
  loadingUnloadingDate: 'Loading/unloading date',
  contentStatus: 'Content status',
  dates: 'Dates',
  cargo: 'Cargo',
  shippingUnit: 'Shipping unit',
  locations: 'Locations',
  selectAtLeastOneOption: 'Please select at least one option.',
  loadingYard: 'Loading yard',
  shippingUnitStatus: 'Shipping unit status',
  suNumber: 'SU number',
  noResultForSearch: 'There are no results for your search query',
  transportStatus: 'Transport status',
  vessel: 'Vessel',
  currentLocation: 'Current location',
  locationIsMissing: 'Location is missing',
  failedToCreateShiftMove: 'Failed to create shift move',
  createShiftMoveQuestion: 'Are you sure you want to create a shift task for this shipping unit?',
  shiftShippingUnit: 'Shift shipping unit',
  cancelShift: 'Cancel shift',
  taskCancelled: 'Task cancelled',
  creatingShiftMove: 'Creating shift move',
  cancellingShiftMove: 'Cancelling shift move',
  undoTask: 'Undo task',
  undoNotPossibleOnlyLastTaskCanBeUndone:
    'Undo is not possible. Only the last finished task related to the shipping unit can be undone.',
  version: 'Version',
  resetTablesToDefault: 'Reset tables to defaults',
  resetting: 'Resetting...',
  listResetToDefault: 'List reset to default',
  reset: 'Reset',
  leave: 'Leave',
  blockIsInactive: 'Block is inactive',
  notSupposedToHappen: 'That is not supposed to happen',
  taskStatus: 'Task Status',
  content: 'Content',
  moveType: 'Move type',
  urgent: 'Urgent',
  unloading: 'Unloading',
  applicationDownloaded: 'Application downloaded',
  newVersionAvailable: 'New version available',
  'newVersionAvailable.message': 'Please update the app to get the latest features and bug fixes!',
  update: 'Update',
  showMore: 'Show more',
  yardTo: 'Yard to',
  yardFrom: 'Yard from',
  loadingFinished: 'Loading finished',
  unLoadingPlannedDate: '(Un)loading planned date',
  unLoadingDate: '(Un)loading date',
  unLoadingTime: '(Un)loading time',
  finishUnloading: 'Finish unloading',
  undoLoadingFinishedConfirmationMessage:
    'Are you sure you want undo unloading finished for this shipping unit? The unloading data will be lost.',
  shippingUnitEmpty: 'Shipping unit empty',
  finishLoading: 'Finish loading',
  shippingUnitMustBeInOrCheckedOut:
    'Mark (un)loading finished is not possible because shipping unit is in incorrect state. Shipping unit must be in or checked out.',
  shippingUnitMustNotBeRejected:
    'Mark (un)loading finished is not possible. Shipping Unit must not be Rejected',
  unlodingDateCantBeInFuture: '(Un)loading date cannot be in the future.',
  finishingUnloadingNotPossible: 'Finish (un)loading not possible',
  unloadingTimeIsRequired:
    '(Un)loading time is required and must be in a valid corresponding format',
  unloadingDateIsRequired:
    '(Un)loading date is required and must be in a valid corresponding format',
  unloadingTimeCantBeInFuture: '(Un)loading time cannot be in the future',
  shippingUnitFull: 'Shipping unit full',
  goBackQuestion: 'Go back?',
  areYouSureYouWantToGoBack:
    'Are you sure you want to go back? The “to” location you have chosen will not be saved.',
  goBackToYardOverview: 'Go back to yard overview?',
  areYouSureYouWantToGoBackToYardOverview:
    'Are you sure you want to go back to the yard overview? The “to” locations you have chosen will not be saved.',
  quickShift: 'Quick shift',
  cancelQuickShift: 'Cancel quick shift',
  shifted: 'Shifted',
  failed: 'Failed',
  selectAll: 'Select all',
  'numberOf:total:shippingUnitsSelected': '{number} of {total} shipping units selected',
  'number:shippingUnitsShifted': '{number} shipping units shifted.',
  'number:shippingUnitsShiftedFailed': '{number} shipping units shifted, {failed} failed.',
  'number:shippingUnitsShiftFailed': '{number} shipping units failed to shift.',
  missingLocation: 'Missing location',
  thisShippingUnitIsMissingALocation: 'This shipping unit is missing a location. Is it on site?',
  failedToGetLocation: 'Failed to get location',
  failedToGetLocationMessage:
    'Failed to get location. This may happen when your internet connection is unstable. Please try again later.',
  shiftOtherSu: 'Shift other SU',
  backToOutMove: 'Back to out move',
  suWithQueuedTasks: 'SU with queued tasks',
  'shippingUnits:suWithQueuedTasksMessage':
    'The shipping unit(s) {shippingUnits} currently have queued tasks. Shifting these might impact the way in which the queued tasks have to be finished.',
  continue: 'Continue',
  queuedOn: 'Queued on',
  type: 'Type',
  shippingUnitKind: 'Shipping unit kind',
  closingOrDetentionDate: 'Closing/detention date',
  finishedOn: 'Finished on',
  alternativeToLocation: 'Alternative to location',
  pageCouldNotBeLoaded: 'Page could not be loaded',
  pageCouldNotBeLoadedMessage: `You are currently offline. This page can't refresh with new data. You'll only be able to view information that was loaded before you lost connection.`,
  blockedBy: 'Blocked by',
  reason: 'Reason',
  blocked: 'Blocked',
  shiftingShippingUnitNotPossible: 'Shifting shipping unit not possible',
  shiftingShippingUnitNotPossibleMessage:
    'This shipping unit is blocked for movement. Shifting it is therefore not possible. Contact your admin to get more information about how to solve this situation.',
  pleasePutViewsInOrder: 'Please put your views in the correct order',
  rejected: 'Rejected',
  unloadingFinished: 'Unloading finished',
  forbidden: 'Forbidden',
  forbiddenMessage:
    'You do not have permission to access the application. Request access from your admin.',
  operatorDeactivatedMessage: 'Current operator has been deactivated, please log out.',
  errorWhileGettingTaskDetails: 'Encountered an error while getting task details.',
  reload: 'reload'
}
